<template>
  <div class="main-container">
    <label class="item-label mt-5" v-if="label">{{ label }}</label>
    <validation-provider rules="gapfill|required" v-slot="{ errors }">
      <div class="textarea-container">
        <div class="tools">
          <div style="flex: 1"></div>
          <div class="item">
            <fa icon="copy" class="copy" @click="copyToClipboard(fullParagraph())"/>
          </div>
          <div class="item" v-if="!withinLevel">
            <div class="info" >
              <fa icon="info-circle" />
            </div>
            <div class="result" v-if="!withinLevel || errors.length > 0">
              {{ errors[0] }} <br>
              <div v-for="r in cefrLevelsResultArray" :key="r.name">

                <div class="level">{{ r.name }}: </div>
                <div class="value">{{ r.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="highlights" ref="highlights" v-html="highlightsHtml">
          <!-- cloned text with <mark> tags here -->
        </div>
        <div class="grow-wrap">
          <textarea
            class="item-textarea"
            :class="{ 'red-border': !withinLevel }"
            ref="textarea"
            rows="7"
            type="text"
            v-model="withBrackets"
            @input="emitInput(withGapfill(withBrackets))"
            @blur="trim"
            :placeholder="placeholder"
            :placeholderOnFocus="placeholderOnFocus"/>
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import * as clipboardy from "clipboardy";

const ua = window.navigator.userAgent.toLowerCase();
const isIE = !!ua.match(/msie|trident\/7|edge/);

export default {
  name: "GapfillTextara",
  props: {
    value: String,
    answer: String,
    label: String,
    placeholder: String,
    placeholderOnFocus: String,
    cefrLevel: null,
    maxlength: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      valueObject: this.value,
      highlightsHtml: "",
      debounce: null,
      cefrLevelsResultArray: [],
      withinLevel: true,
      showCefrLevel: false,
      answerObject: this.answer,
      id: uuidv4()
    };
  },
  computed: {
    withBrackets: {
      get: function() {
        return this.valueObject?.replace("<%GAP%>", `[${this.answerObject}]`);
      },
      set: function(value) {
        this.valueObject = value;

        if (value.includes("[") && value.includes("]")) {
          const answer = value.substring(
            value.indexOf("[") + 1,
            value.indexOf("]")
          );
          this.$emit("updateAnswer", answer);
          this.answerObject = answer;
        }
      }
    }
  },
  methods: {
    emitInput(content) {
      this.$emit("input", content);
      this.$store.commit('item/setWordCount', content);
    },
    handleInput() {
      this.$refs.textarea.parentNode.dataset.replicatedValue = this.withBrackets;
      const text = this.withBrackets;
      let textToHighlight = "";
      let answer = "";
      if (text.includes("[")) {
        const startIndex = text.indexOf("[");
        const endIndex =
          text.indexOf("]") > startIndex ? text.indexOf("]") : text.length;
        answer = text.substring(startIndex + 1, endIndex);
        textToHighlight = `${text.substring(
          0,
          startIndex
        )}<mark>${text.substring(
          startIndex,
          endIndex + 1
        )}</mark>${text.substring(endIndex, text.length - 1)}`;
      } else {
        textToHighlight = text;
      }

      this.highlightsHtml = textToHighlight;
      this.$emit("updateAnswer", answer);
      this.answerObject = answer;
    },
    applyHighlights(text) {
      text = text
        .replace(/\n$/g, "\n\n")
        .replace(/[A-Z].*?\b/g, "<mark>$&</mark>");

      if (isIE) {
        // IE wraps whitespace differently in a div vs textarea, this fixes it
        text = text.replace(/ /g, " <wbr>");
      }

      return text;
    },
    withGapfill(text) {
      return text.replace(`[${this.answerObject}]`, "<%GAP%>");
    },
    fullParagraph() {
      const text = this.withBrackets.replace("[", "").replace("]", "");
      return text;
    },
    async copyToClipboard(text) {
      await clipboardy.write(text);
    },
    trim() {
      this.withBrackets = this.withBrackets.trim();
    },
  },
  watch: {
    answer: {
      handler(newVal) {
        if (this.withBrackets.includes("]")) {
          const text = this.withBrackets;
          const startIndex = text.indexOf("[");
          const endIndex = text.indexOf("]") + 1;
          this.withBrackets = text.replace(
            text.substring(startIndex, endIndex),
            `[${newVal}]`
          );
          this.answerObject = newVal;
        }
      }
    },
    withBrackets() {
      this.handleInput();
    },
  },
  async mounted() {
    this.handleInput();
  }
};
</script>


<style lang="scss" scoped>

.main-container {
  display: flex;
  flex-direction: column;
  max-width: 550pt;
  height: fit-content;
  margin-bottom: 20pt;
  box-sizing: border-box;

  .textarea-container {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 9pt;
    transform: translateZ(0);
    -webkit-text-size-adjust: none;
    background-color: $control-background;
    border-radius: 4pt;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    transition: all ease-in-out 200ms;

    .highlights, textarea {
      font-family: unset;
      font-size: 1.1em;
      line-height: 1.3em;
      margin-top: 5pt;
    }
  }


  textarea {
    z-index: 2;
    background: transparent;
  }

  .highlights {
    display: block;
    position: absolute;
    z-index: 1;
    border-radius: 3pt;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
    pointer-events: none;
    background-color: transparent;
    overflow: auto;
    border: 1pt solid rgba(255, 255, 255, 0);
    margin-right: 8pt;
    padding: 5pt;
  }
}

</style>

<style>
  mark {
    display: inline;
    border-radius: 2.5pt;
    color: transparent;
    background-color: #CDEBC3;
    line-height: 1.1em;
    z-index: 0;
    font-family: unset;
    font-size: 1em;
  }
</style>